import React from "react"
import Layout from "../components/layout"

const MentionsLegales = () => {
  return (
    <Layout>
      <h1 className="breadcrumb">Mentions legales</h1>
      <div className="container">
        <p>
          <b>Éditeur/Directeur de publication :</b> iAROSS
        </p>
        <p>
          <b>Hébergement : </b>
          Netlify – 610 22nd Street, Suite 315 CA 94107 San Francisco +1 (844)
          899-7312
        </p>
        <p>
          <b>Responsable données personnelles (RGPD) :</b>
          Camille Muller - Label Folie - +33 6 19 67 45 04 -
          https://labelfolie.com
        </p>
        <p>
          <b>Cookies : </b>
          Ce site utilise des cookies. Ces cookies sont utilisés pour collecter
          des informations sur la façon dont vous interagissez avec ce site Web
          et nous permettent de nous souvenir de vous. Nous utilisons ces
          informations afin d'améliorer et de personnaliser votre expérience de
          navigation et pour des analyses et des mesures concernant nos
          visiteurs sur ce site Web. Si vous refusez, vos informations ne seront
          pas suivies lorsque vous visiterez ce site Web. Un seul cookie sera
          utilisé dans votre navigateur pour mémoriser votre préférence de ne
          pas être suivi.
        </p>{" "}
      </div>
    </Layout>
  )
}

export default MentionsLegales
